<template>
  <model ref="model">
    <div class="unlock-dialog-header">
      <br>
      <div class="vip-badge" v-if="userVip.memberFreePlanInfinity">
        <img :src="getVipIcon(vipInfo.vipRightId)">
        <div>会员免费</div>
      </div>
      <template v-else-if="userVip.remainVipFreeCase<=0 && userVip.vipDiscount && userVip.vipDiscount< 10">
        <div class="vip-badge vip-badge-2">
          <img src="https://cdn.001ppt.cn/assets/img/member/vip-icon01.svg">
          <div>会员{{ vipInfo.vipDiscount }}折</div>
        </div>
        <div class="plan-discount" v-if="plan.isInDiscount">限时{{ plan.discountVal }}折</div>
      </template>
    </div>

    <!--    超级VIP会员-->
    <div class="unlock-dialog" v-if="userVip.memberFreePlanInfinity">
      <div class="title">免费收入囊中</div>
<!--      <bind-email @confirm="unlock"/>-->
      <div class="confirm-btn" @click="unlock">确认解锁下载</div>
    </div>


    <!--    有额度-->
    <div class="unlock-dialog" v-else-if="userVip.remainVipFreeCase > 0">
<!--      <div class="title">免费收入囊中</div>-->
      <div class="title">会员免费方案剩余额度&nbsp;<div class="red" style="padding-left: 4px">{{ vipInfo.remainVipFreeCase }}</div>
      </div>
<!--      <bind-email @confirm="unlock"/>-->
      <div class="confirm-btn" @click="unlock">确认解锁下载</div>
    </div>


    <!--    无额度-->
    <div class="unlock-dialog" v-else-if="vipInfo.remainVipFreeCase <= 0">
      <div class="title">
        确认使用&nbsp;
        <div class="red">¥{{ finalPrice }}</div>
        &nbsp;解锁
      </div>
      <div class="desc" style="padding-bottom: 0" v-if="userVip.vipFreeCase>0">会员免费方案额度已用完</div>
      <div class="desc gray">
        严值剩余{{yanzhiFilter(wallet.totalRemainValue)}}(¥{{moneyFilter(wallet.totalRemainValue/10)}})
      </div>
      <unlock-recharge :need-recharge-money="deltaYanzhi/10" :default-recharge-money="100" @paySuccess="unlock" v-if="deltaYanzhi>0"/>

      <div class="confirm-btn" @click="unlock" v-if="deltaYanzhi<=0">确认解锁下载</div>

      <div class="extra">*确认解锁后，严值将自动抵扣</div>
      <div class="link" @click="showVipPopup"><img
        src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">免费解锁了解一下 <van-icon name="arrow" />
      </div>
    </div>


    <!--    试用会员，有额度-->
    <div class="unlock-dialog" v-else-if="vipInfo.vipRightId === '1' && vipInfo.remainVipFreeCase > 0">
<!--      <div class="title">免费收入囊中</div>-->
      <div class="title">会员免费方案剩余额度&nbsp;<div class="red" style="padding-left: 4px">{{ vipInfo.remainVipFreeCase }}</div>
      </div>
<!--      <bind-email @confirm="unlock"/>-->
      <div class="confirm-btn" @click="unlock">确认解锁下载</div>
    </div>


<!--    &lt;!&ndash;    试用会员，无额度&ndash;&gt;-->
<!--    <div class="unlock-dialog" v-else-if="vipInfo.vipRightId === '1' && vipInfo.remainVipFreeCase === 0">-->
<!--      <div class="title">-->
<!--        确认使用&nbsp;-->
<!--        <div class="red">¥{{ finalPrice }}</div>-->
<!--        &nbsp;解锁-->
<!--      </div>-->
<!--      <div class="desc" style="padding-bottom: 0">会员免费方案额度已用完</div>-->
<!--      <div class="desc gray">-->
<!--        严值剩余{{yanzhiFilter(wallet.totalRemainValue)}}(¥{{moneyFilter(wallet.totalRemainValue/10)}})-->
<!--      </div>-->
<!--      <unlock-recharge :need-recharge-money="deltaYanzhi/10" :default-recharge-money="100" @paySuccess="unlock" v-if="deltaYanzhi>0"/>-->

<!--      <div class="confirm-btn" @click="unlock" v-if="deltaYanzhi<=0">确认解锁下载</div>-->
<!--      <div class="extra">*确认解锁后，严值将自动抵扣</div>-->
<!--      <div class="link" @click="showVipPopup"><img-->
<!--        src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">免费解锁了解一下 <van-icon name="arrow" />-->
<!--      </div>-->
<!--    </div>-->


    <!--    普通用户-->
<!--    <div class="unlock-dialog" v-else-if="vipInfo.vipRightId === '3'">-->
<!--      <div class="title">-->
<!--        确认使用&nbsp;-->
<!--        <div class="red">¥{{ finalPrice }}</div>-->
<!--        &nbsp;解锁-->
<!--      </div>-->
<!--      <div class="desc gray">-->
<!--        严值剩余{{yanzhiFilter(wallet.totalRemainValue)}}(¥{{moneyFilter(wallet.totalRemainValue/10)}})-->
<!--      </div>-->
<!--      <unlock-recharge :need-recharge-money="deltaYanzhi/10" :default-recharge-money="100" @paySuccess="unlock" v-if="deltaYanzhi>0"/>-->

<!--      <div class="confirm-btn" @click="unlock" v-if="deltaYanzhi<=0">确认解锁下载</div>-->
<!--      <div class="extra">*确认解锁后，严值将自动抵扣</div>-->
<!--      <div class="link" @click="showVipPopup"><img-->
<!--        src="https://cdn.001ppt.cn/assets/img/member/vip-icon02.svg">免费解锁了解一下 <van-icon name="arrow" />-->
<!--      </div>-->
<!--    </div>-->
  </model>
</template>


------------------ script ------------------
<script>
import Model from "../../../components/model/model";
import BindEmail from "./bindEmail";
import {unlockMixin} from "./unlockMixin";
import UnlockRecharge from "./unlockRecharge";
import {rechargeV2Api} from "../../../api/v2/rechargeV2Api";
import {sleep} from "../../../config/util";

export default {
  components: {UnlockRecharge, BindEmail, Model},
  mixins: [unlockMixin],
}
</script>


------------------ styles ------------------
<style lang="less" scoped>
@import "./unlock";
</style>
